p>code {
    background: var(--mk--BackgroundColor--350);
    padding: 1px var(--mk--spacer--xs);
}

pre {
    background: var(--mk--BackgroundColor--350);
    padding: var(--mk--spacer--xs);
    margin: var(--mk--spacer--sm) 0;
    min-width: 100%;
}

pre .hll {
    background-color: #ffffcc
}

pre {
    background: #f0f0f0;
}

pre .c {
    color: #60a0b0;
    font-style: italic
}

/* Comment */
pre .err {
    border: 1px solid #FF0000
}

/* Error */
pre .k {
    color: #007020;
    font-weight: bold
}

/* Keyword */
pre .o {
    color: #666666
}

/* Operator */
pre .ch {
    color: #60a0b0;
    font-style: italic
}

/* Comment.Hashbang */
pre .cm {
    color: #60a0b0;
    font-style: italic
}

/* Comment.Multiline */
pre .cp {
    color: #007020
}

/* Comment.Preproc */
pre .cpf {
    color: #60a0b0;
    font-style: italic
}

/* Comment.PreprocFile */
pre .c1 {
    color: #60a0b0;
    font-style: italic
}

/* Comment.Single */
pre .cs {
    color: #60a0b0;
    background-color: #fff0f0
}

/* Comment.Special */
pre .gd {
    color: #A00000
}

/* Generic.Deleted */
pre .ge {
    font-style: italic
}

/* Generic.Emph */
pre .gr {
    color: #FF0000
}

/* Generic.Error */
pre .gh {
    color: #000080;
    font-weight: bold
}

/* Generic.Heading */
pre .gi {
    color: #00A000
}

/* Generic.Inserted */
pre .go {
    color: #888888
}

/* Generic.Output */
pre .gp {
    color: #c65d09;
    font-weight: bold
}

/* Generic.Prompt */
pre .gs {
    font-weight: bold
}

/* Generic.Strong */
pre .gu {
    color: #800080;
    font-weight: bold
}

/* Generic.Subheading */
pre .gt {
    color: #0044DD
}

/* Generic.Traceback */
pre .kc {
    color: #007020;
    font-weight: bold
}

/* Keyword.Constant */
pre .kd {
    color: #007020;
    font-weight: bold
}

/* Keyword.Declaration */
pre .kn {
    color: #007020;
    font-weight: bold
}

/* Keyword.Namespace */
pre .kp {
    color: #007020
}

/* Keyword.Pseudo */
pre .kr {
    color: #007020;
    font-weight: bold
}

/* Keyword.Reserved */
pre .kt {
    color: #902000
}

/* Keyword.Type */
pre .m {
    color: #40a070
}

/* Literal.Number */
pre .s {
    color: #4070a0
}

/* Literal.String */
pre .na {
    color: #4070a0
}

/* Name.Attribute */
pre .nb {
    color: #007020
}

/* Name.Builtin */
pre .nc {
    color: #0e84b5;
    font-weight: bold
}

/* Name.Class */
pre .no {
    color: #60add5
}

/* Name.Constant */
pre .nd {
    color: #555555;
    font-weight: bold
}

/* Name.Decorator */
pre .ni {
    color: #d55537;
    font-weight: bold
}

/* Name.Entity */
pre .ne {
    color: #007020
}

/* Name.Exception */
pre .nf {
    color: #06287e
}

/* Name.Function */
pre .nl {
    color: #002070;
    font-weight: bold
}

/* Name.Label */
pre .nn {
    color: #0e84b5;
    font-weight: bold
}

/* Name.Namespace */
pre .nt {
    color: #062873;
    font-weight: bold
}

/* Name.Tag */
pre .nv {
    color: #bb60d5
}

/* Name.Variable */
pre .ow {
    color: #007020;
    font-weight: bold
}

/* Operator.Word */
pre .w {
    color: #bbbbbb
}

/* Text.Whitespace */
pre .mb {
    color: #40a070
}

/* Literal.Number.Bin */
pre .mf {
    color: #40a070
}

/* Literal.Number.Float */
pre .mh {
    color: #40a070
}

/* Literal.Number.Hex */
pre .mi {
    color: #40a070
}

/* Literal.Number.Integer */
pre .mo {
    color: #40a070
}

/* Literal.Number.Oct */
pre .sa {
    color: #4070a0
}

/* Literal.String.Affix */
pre .sb {
    color: #4070a0
}

/* Literal.String.Backtick */
pre .sc {
    color: #4070a0
}

/* Literal.String.Char */
pre .dl {
    color: #4070a0
}

/* Literal.String.Delimiter */
pre .sd {
    color: #4070a0;
    font-style: italic
}

/* Literal.String.Doc */
pre .s2 {
    color: #4070a0
}

/* Literal.String.Double */
pre .se {
    color: #4070a0;
    font-weight: bold
}

/* Literal.String.Escape */
pre .sh {
    color: #4070a0
}

/* Literal.String.Heredoc */
pre .si {
    color: #70a0d0;
    font-style: italic
}

/* Literal.String.Interpol */
pre .sx {
    color: #c65d09
}

/* Literal.String.Other */
pre .sr {
    color: #235388
}

/* Literal.String.Regex */
pre .s1 {
    color: #4070a0
}

/* Literal.String.Single */
pre .ss {
    color: #517918
}

/* Literal.String.Symbol */
pre .bp {
    color: #007020
}

/* Name.Builtin.Pseudo */
pre .fm {
    color: #06287e
}

/* Name.Function.Magic */
pre .vc {
    color: #bb60d5
}

/* Name.Variable.Class */
pre .vg {
    color: #bb60d5
}

/* Name.Variable.Global */
pre .vi {
    color: #bb60d5
}

/* Name.Variable.Instance */
pre .vm {
    color: #bb60d5
}

/* Name.Variable.Magic */
pre .il {
    color: #40a070
}

.linenos {
    width: 30px
}

/* modification for button copy */
.highlight>pre>div {
    display: flex;
    flex-direction: row-reverse;
}

/* modification for button copy */

.btn-copy {
    color: #272822;
    background-color: #FFF;
    border-color: #272822;
    border: 2px solid;
    border-radius: 3px 3px 0px 0px;

    /* right-align */
    display: block;
    margin-left: auto;
    margin-right: 0;

    margin-bottom: -2px;
    padding: 3px 8px;
    font-size: 0.8em;
}

.btn-copy:hover {
    cursor: pointer;
    background-color: #F2F2F2;
}

.btn-copy:focus {
    /* Avoid an ugly focus outline on click in Chrome,
       but darken the button for accessibility.
       See https://stackoverflow.com/a/25298082/1481479 */
    background-color: #E6E6E6;
    outline: 0;
}

.btn-copy:active {
    background-color: #D9D9D9;
}

.highlight pre {
    /* Avoid pushing up the copy buttons. */
    margin: 0;
}
