
/**
* Basic styling
*/
body {
  font-family: $kv-font-family--base;
  font-size: $kv-font-size--base;
  line-height: $kv-line-height--base;
  font-weight: 300;
  color: $kv-color--black-500;
  background-color: $kv-color--white;
  -webkit-text-size-adjust: 100%;
}

/**
* Code formatting
*/
pre,
code {
  font-size: 1rem;
  border: 1px solid $kv-color--black-200;
  border-radius: 3px;
  background-color: $kv-color--black-150;
  padding: 1px .5rem;
}
.highlight {
  background-color: $kv-color--black-150 !important;
  margin-bottom: 1rem;
}
code {
  padding: 1px 5px;
  color: $kv-color--green-450;
}
pre {
  padding: 8px 12px;
  overflow-x: scroll;
  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

.post-content {
  blockquote {
    border-left: 2px solid #ccc;
    padding-left: 1rem;
  }
}