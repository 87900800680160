.kv-homepage-grid {
  padding: 5rem 5rem 0 5rem;
  @media (min-width: 900px) {
    display: grid;
    gap: 5rem;
    grid-template-columns: 3fr 1fr;
    grid-template-areas:
    "why posts"
    "what posts"
    "endusers endusers"
    "vendors vendors"
    "integrations integrations";
  }
}

.kv-why {
  grid-area: why;
}

.kv-what {
  grid-area: what;
  @media (min-width: 700px) {
    display: grid;
    gap: 5rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: min-content min-content min-content;
    grid-template-areas:
    "title title title"
    "img-1 img-2 img-3"
    "txt-1 txt-2 txt-3"
    ;
    &__title {
      grid-area: title;
    }
    &__img{
      align-self: center;
      justify-self: center;
      &:nth-of-type(1) {
        grid-area: img-1;
      }
      &:nth-of-type(2) {
        grid-area: img-2;
      }
      &:nth-of-type(3) {
        grid-area: img-3;
      }
    }
    &__text {
      text-align: center;
      &:nth-of-type(1) {
        grid-area: txt-1;
      }
      &:nth-of-type(2) {
        grid-area: txt-2;
      }
      &:nth-of-type(3) {
        grid-area: txt-3;
      }
    }
  }
  &__img, &__text {
    margin: .5rem auto;
    display: block;
    text-align: center;
    max-width: 75vw;
  }
}

.kv-recent-posts {
  grid-area: posts;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.kv-endusers {
  grid-area: endusers;
  padding: 5rem;
  text-align: center;
  img { width: 200px; margin: 2rem; }
}

.kv-vendors {
  grid-area: vendors;
  padding: 5rem;
  text-align: center;
  img { width: 200px; margin: 2rem; }
}

.kv-integrations {
  grid-area: integrations;
  padding: 5rem;
  text-align: center;
  img { width: 200px; margin: 2rem; }
}

.kv-blog__snippet {
  border-radius: 5px;
  font-size: 1rem;
  background: linear-gradient(to bottom, rgba($kv-color--green-300, 0.7), rgba($kv-color--green-450, 0.7));
  &:not(:last-child){
    margin-bottom: .5rem;
  }
  &__link {
    padding: 1rem;
  display: block;
  }
  h4 {
    font-weight: bolder;
  }
  & * {
    color: #fff;
  }
  &:hover, &:active, &:focus {
      background: linear-gradient(to bottom, rgba($kv-color--green-300, 1), rgba($kv-color--green-450, 1));

  }
}

.row-community {
  &-title {
    min-height: 10vh;
  }
  &-buttons {
    min-height: 25vh;
  }
  &-calendar {
    min-height: 10vh;
  }
  &-networks {
    min-height: 25vh;
    background-color: $kv-color--black-150;
    &--twitter-feed {
      background-color: $kv-color--black-150;
    }
  }
}

.page-title {
  padding-top: 60px;
  margin-bottom: 40px;
  @media (max-width: 992px) {
    padding-top: 20px;
    padding-left: 15px;
  }
}

.page-subtitle--spacer_top {
  margin-top: 60px;
}

.page-subtitle--spacer_bottom {
  margin-bottom: 40px;
}

.page-paragraph--spacer_bottom {
  margin-bottom: 60px;
}

.pagination {
  margin: 0 auto;
  .btn.btn-outline-light {
    cursor: default;
    &:hover {
      color: #f8f9fa; //override default hover behavior
      background-color: transparent; //override default hover behavior
      border-color: #f8f9fa; //override default hover behavior
    }
  }
}

.community-page {
  .page-title {
    margin-bottom: 0;
    @media (max-width: 992px) {
      padding-left: 0;
      margin-bottom: 10px;
    }
  }
}

.doc-intro-text {
  max-width: 85%;
  height: 7.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.kubevirt-can-do {
  .card {
    border: none;
    &-footer {
      min-height: 8rem;
      background-color: transparent;
      border: none;
      border-radius: 0;
    }
  }
}
