$kv-font-family--base: 'Open Sans', sans-serif;
$kv-font-size--base:   16px;
$kv-font-size--small:  $kv-font-size--base * 0.875;
$kv-line-height--base: 1.5;

html {
  font-family: sans-serif;
  line-height: 1.43;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-size: $kv-font-size--base;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: $kv-color--white;
}

h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 3rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: $kv-color--green-400;
}

h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.714rem;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: $kv-color--green-500;
}

h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.285rem;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  color: $kv-color--green-700;
}

h4 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  color: $kv-color--green-700;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  color: $kv-color--black-500;
  line-height: 1.5;
}

a {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  color: $kv-color--green-500;
  &:hover {
    color: $kv-color--green-300;
    text-decoration: none;
  }
}

h2 a {
  font-size: 1.714rem;
}

ul,
ol {
  font-size: 1rem;
  font-weight: 400;
  color: $kv-color--black-500;
}
