$kv-color--white: #fff;
$kv-color--black-50: #fafafa;
$kv-color--black-100: #dee6ea;
$kv-color--black-150: #f5f5f5;
$kv-color--black-200: #b1b1b1;
$kv-color--black-300: #8b8d8f;
$kv-color--black-400: #72767b;
$kv-color--black-500: #4d5258; // default text color
$kv-color--black-600: #4a4a4a;
$kv-color--black-700: #231f20; // GitHub icon (hover)
$kv-color--blue-500: #1da1f2; // Twitter icon (hover)
$kv-color--green-300: #00aab2;
$kv-color--green-400: #07a692; // Calendar icon (hover)
$kv-color--green-450: #0099a0;
$kv-color--green-500: #00797f;
$kv-color--green-600: #006166;
$kv-color--green-700: #005c61;
$kv-color--gold-100: #ffe986;
$kv-color--gold-300: #fcdc4b;
$kv-color--gold-500: #fed476;
$kv-color--gold-600: #ffb300; // Mail icon (hover)
$kv-color--gold-700: #fa742b;
$kv-color--gold-800: #fd5900;
