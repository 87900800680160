.nav-item {
  margin-bottom: -8px;
  border-bottom: 3px solid transparent;
  @media (max-width: 992px) {
    margin-bottom: 0;
  }
  // a.nav-link {
  //   opacity: .8;
  // }
  
  &:not(:last-child) {
    &:hover, &:focus {
      margin-bottom: -8px;
      border-bottom: 3px solid $kv-color--gold-500;
      a.nav-link {
        color: rgba(255, 255, 255, .75);
        opacity: 1;
      }
      @media (max-width: 992px) {
        margin-bottom: 0;
      }
    }
  }
  &.active {
    margin-bottom: -8px;
    border-bottom: 3px solid $kv-color--green-300;
    &:hover {
      border-bottom-color: $kv-color--gold-500;
    }
    @media (max-width: 992px) {
      margin-bottom: 0;
    }
  }
}
.navbar-expand-lg {
  background-image: linear-gradient(to top, #3accc5, #00aab2);
  width: 100%;
  .navbar-brand-image {
    height: 30px;
  }
  &.navbar-home {
    .navbar-brand-image {
      height: 36px;
    }
    @media (min-width: 992px) {
      background-image: none !important;
      transition: background-image .5s ease-in-out,padding .5s ease-in-out;
      -webkit-transition: background-image .5s ease-in-out,padding .5s ease-in-out;
      -moz-transition: background-image .5s ease-in-out,padding .5s ease-in-out;
      padding: 20px 40px;
      &.scroll-collapse {
        background-image: linear-gradient(to top, #3accc5, #00aab2) !important;
        opacity: .95;
      }
    }
    // .navbar-collapse {
    //   &.collapse,
    //   &.collapsing,
    //   &.collapse.show {
    //     ul {
    //       align-items: flex-start;
    //     }
    //   }
    // }
  }
  .navbar-collapse {
    &.collapse,
    &.collapsing,
    &.collapse.show {
      ul {
        align-items: flex-start;
      }
    }
  }
  .navbar-collapse {
    align-self: flex-end;
    justify-content: flex-end;
  }
}
.navbar-dark {
  .navbar-toggler {
    &:focus {
      outline: 5px auto $kv-color--green-450;
    }
  }
}

.labs-title {
  margin-left: 25px;
}
.docs-title,
.labs-title {
  &--item {
    display: flex;
    flex: 1;
    font-size: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 6px;
    border-left: 3px solid transparent;
    color: $kv-color--black-600;
    opacity: .85;
    &:hover {
      background-color: $kv-color--black-150;
      color: $kv-color--black-600;
    }
    &.active {
      border-left: 3px solid $kv-color--green-300;
      color: $kv-color--black-600;
    }
  }
}

.blogs-navigation,
.video-navigation,
.docs-navigation,
.labs-navigation {
  list-style: none;
  margin-bottom: 0;
  &--item {
    a {
      display: flex;
      flex: 1;
      &:hover {
        background-color: $kv-color--black-150;
      }
    }
    &_link {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 6px;
      border-left: 3px solid transparent;
      font-size: 18px;
      color: $kv-color--black-500;
      opacity: .85;
      &:hover {
        color: $kv-color--black-600;
      }
      &.active {
        border-left: 3px solid $kv-color--green-300;
        color: $kv-color--black-600;
      }
    }
  }
  &_sub {
    list-style: none;
    &--item {
      a {
        &:hover {
          background-color: $kv-color--black-150;
        }
      }
      &_link {
        margin-top: 5px;
        margin-bottom: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 6px;
        border-left: 3px solid transparent;
        font-size: 18px;
        color: $kv-color--black-500;
        opacity: .85;
        &:hover {
          color: $kv-color--black-600;
        }
        &.active {
          border-left: 3px solid $kv-color--green-300;
          color: $kv-color--black-600;
        }
      }
    }
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.navbar-brand {
  padding: 0;
}
