.card--sidebar {
  border-color: transparent;
}
.card-header {
  &--sidebar {
    background-color: transparent;
    // border: 1px solid $kv-color--black-200;
  }
}
.card-body {
  display: flex;
  margin: auto;
  height: 8rem;
  &--sidebar {
    display: block;
    height: auto;
  }
}
