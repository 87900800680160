.timeline-Widget.timeline-Widget--edge {
  max-width: 100% !important;
  width: 100% !important;
}
.twitter {
  &-arrow_up {
    width: 25px;
    background: transparent;
    border-top: 5px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 25px solid $kv-color--black-50;
    border-left: 20px solid transparent;
    margin-left: 7rem;
  }
  &-feed {
    background-color: $kv-color--black-50;
    &--title {
      margin-bottom: 0;
      padding: .5rem 1rem;
    }
    &--icon_share {
      float: right;
      margin-top: .5rem;
    }
    &--timeline {
      &-background {
        background-color: $kv-color--white;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
      }
    }
    &--footer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-top: 1rem;
      padding-bottom: 1rem;
      background-color: $kv-color--white;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
      &-wrapper {
        height: 60px;
        width: 100%;
        margin-right: 1rem;
        margin-left: 1rem;
        padding: 1rem;
        background-color: $kv-color--black-50;
        border: 1px solid #f1f0f0;
        border-radius: 2px;
        &_inner {
          // padding-left: 1rem;
          &-icon {
            color: #1da1f2;
          }
          &-text {
            color: #878787;
          }
        }
      }
    }
  }
}
