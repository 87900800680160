table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border: 1px solid #dee2e6;
  tr {
    th {
      border-bottom-width: 2px;
    }
  }
  thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  th, td {
    padding: .75rem;
    border: 1px solid #dee2e6;
    vertical-align: top;
  }
}
