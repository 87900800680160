.hero {
  background-image: linear-gradient(to top, #3accc5, #00aab2);
  background-size: cover;
  min-height: 80vh;
  color: $kv-color--white;
  text-align: center;
  &-overlay {
    background: url(./../assets/images/hero-overlay.svg);
    background-size: cover;
    background-position: top center;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 50px;
    @media (max-width: 992px) {
      padding-top: 75px;
    }
    .row {
      min-height: 12rem;
    }
    &-text {
      color: $kv-color--white;
      margin-top: 4rem;
    }
    &__action {
      margin-top: 1rem;
     display: inline-flex;
    } 
  }

  .kv-card-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    @media (min-width:992px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: min-content 1fr;
      gap: .5rem;
      grid-template-areas:
      "header header header header"
      "card-1 card-2 card-3 card-4";
      grid-row: .2rem;
    }
  }
  .kv-card-group__header {
    grid-area: header;
    margin-bottom: 1rem;
    & > * { 
      color: #fff;
    }
  }

  .kv-card {
    background-image: linear-gradient(to bottom, $kv-color--green-300, $kv-color--green-450);
    background-color: $kv-color--green-450;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .08);
    margin-bottom: 1rem;
    border-radius: 5px;
    padding: 1rem;
    display: flex;
    flex-direction:column;
    color: $kv-color--white;
    
    &-body {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 1rem;
      min-height: 100px;
    }
    &-text {
      margin: auto 0 0 0;
      color: $kv-color--white;
      font-size: 18px;
    }
    .kv-btn {
      margin-top: auto;
    }
    &:nth-of-type(1){
      grid-area: card-1;
    }
    &:nth-of-type(2){
      grid-area: card-2;
    }
    &:nth-of-type(3){
      grid-area: card-3;
    }
    &:nth-of-type(4){
      grid-area: card-4;
    }
  }
}


/*
    GitHub URL: https://github.com/gucastiliao/video-popup-js
*/

.videopopupjs{
  background-color:#000;
  background-color:rgba(0,0,0,0.6);
  width:100%;
  height:100%;
  position:fixed;
  top:0;
  left:0;
  z-index:99999;
  text-align: start!important;
}
.videopopupjs--hide{
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: videoPopupJsHide;
  animation-name: videoPopupJsHide;
}
.videopopupjs__close{
  width:30px;
  height:30px;
  cursor:pointer;
  display:block;
  margin-bottom:10px;
  color:rgba(255, 255, 255, 0.59);
}
.videopopupjs__close:after{
  width:30px;
  height:30px;
  display:block;
  text-align:center;
  content:'X';
  font-family:'Verdana';
  border-radius:50%;
  background:#333;
  line-height:2.2;
  font-size:13px;
}
.videopopupjs__close:hover{
  opacity:0.5;
}
.videopopupjs--animation{
  opacity: 0;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: videoPopupJs;
  animation-name: videoPopupJs;
}
.videopopupjs__content{
  margin:0 auto;
  height:100%;
  height:500px;
  width:100%;
  margin-top:5%;
}
.videopopupjs__content iframe{
  width:100%;
  height:100%;
}
.videopopupjs__block--notfound{
  position:absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width:100%;
  height:500px;
  background-color:#fff;
  text-align:center;
  vertical-align: middle;
  line-height: 500px;
  font-family:'Arial';
  font-size:20px;
}
@media(max-width: 768px){
  .videopopupjs__content{
      max-width:90%!important;
  }
}
@-webkit-keyframes videoPopupJs{
  0%{
      opacity: 0;
  }

  100%{
      opacity: 1;
  }
}

@keyframes videoPopupJs{
  0%{
      opacity: 0;
  }

  100%{
      opacity: 1;
  }
}

@-webkit-keyframes videoPopupJsHide{
  0%{
      opacity: 1;
  }

  100%{
      opacity: 0;
  }
}

@keyframes videoPopupJsHide{
  0%{
      opacity: 1;
  }

  100%{
      opacity: 0;
  }
}