.premonition {
  $default-color: #5bc0de;
  $default-light-color: #e3edf2;
  $info-color: #50af51;
  $info-light-color: #f3f8f3;
  $warning-color: #f0ad4e;
  $warning-light-color: #fcf8f2;
  $error-color: #d9534f;
  $error-light-color: #fdf7f7;
  $content-color: rgba(0,0,0,0.5);

  display: grid;
  grid-template-columns: 43px auto;
  padding-top: 6px;
  padding-bottom: 6px;
  margin: 30px 0 30px 0;
  background-color: $default-light-color;
  border-left: 4px solid $default-color;
  color: $default-color;

  code {
    background-color: #fff;
    color: $default-color;
  }

  .header {
    font-weight: 700;
    font-size: 15px;
    color: $default-color;
    margin-bottom: 5px;
  }

  .fa {
    font-size: 18px;
    opacity: .3;
    padding-top: 2px;
    padding-left: 20px;
  }
svg {
margin:4px 0 0 15px;
}
  .content {
    color: $content-color;
    padding-right: 40px;
  }

  @mixin box-type($c, $lc) {
    background-color: $lc;
    color: $c;
    border-color: $c;

    a {
      color: $c;
      text-decoration: underline;
    }
    code {
      color: $c;
    }
    .header {
      color: $c;
    }
  }

  &.info { @include box-type($info-color, $info-light-color); }
  &.warning { @include box-type($warning-color, $warning-light-color); }
  &.error { @include box-type($error-color, $error-light-color); }
}
