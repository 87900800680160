.kv-btn {
  color: $kv-color--green-600;
  padding: .5rem 1rem; 
  font-weight: bold;
  font-size: 16px;
  background: #fff;
  border-radius: 5px;
  box-shadow: none;
  display:flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  text-transform: capitalize;
  top: 0;
  svg {
    margin-right: .5rem;
    g {
      fill: $kv-color--gold-600;
    }
    
  }
  transition: all 0.15s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: $kv-color--gold-600;
    top: -2px;

  }

  &-community {
    border: 1px solid $kv-color--green-600;
    
    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
  }
  &-link--sidebar {
    color: $kv-color--green-500;
    &:hover,
    &:active,
    &:focus {
      color: $kv-color--green-300;
      text-decoration: none;
    }
    &::after {
      position: absolute;
      right: 2rem;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: 'Open Sans', sans-serif;
      font-weight: 900;
      //content: "\f107"; // angle-down
    }
    &.collapsed {
      &::after {
        font-family: 'Open Sans', sans-serif;
        font-weight: 900;
        //content: "\f105"; // angle-right
      }
    }
  }
}
