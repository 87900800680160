.search-bar {
  margin-bottom: .5rem;
  padding-bottom: .5rem;
  border-bottom: 1px solid $kv-color--black-200;
}
.docs-search {
  color: $kv-color--green-500;
  position: absolute;
  margin-top: 7px;
  margin-left: 7px;
  &--input {
    // padding-left: 25px;
    
    border: 1px solid #fff;
    @media (min-width: 992px) {
      margin-left: 16px;
      padding: 6px 0 6px 8px;
    }
    color: #fff;
    &::placeholder {
      color: #fff;
    }
  }
  &--container {
    list-style: none;
    padding-left: 0;

  }
  &--results {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
  }
}
