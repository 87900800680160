.posts {
  list-style: none;
  &-date {
    margin-top: .5rem;
    margin-bottom: 1.5rem;
    color: $kv-color--black-400;
    font-size: 12px;
  }
}
.post {
  &-title {
    margin-bottom: 30px;
  }
  &-info {
    margin-bottom: 10px;
  }
  &-author,
  &-meta,
  &-category-name {
    color: $kv-color--black-400;
  }
}
.readmore {
  margin-top: .5rem;
  margin-bottom: 5rem;
  i.fas.fa-angle-right {
    font-weight: 300;
  }
}
